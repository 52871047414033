import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SmartBanner from 'react-smartbanner';
import queryString from 'query-string';
import {
  Container,
  Header,
  Loader,
  Divider,
  Message,
  Progress,
} from 'semantic-ui-react';
import styled, { css } from 'styled-components';
import { Media } from 'src/utils/Media';

import TextSegment from './chapter/textSegment';
import Survey from './survey/survey';
import Cover from './cover/cover';
import language from '../../../config/language';
import {
  DeviceMinWidths,
  MaxTextWidth,
  MediaQuery,
} from '../../../constants/size';

import '../../../../node_modules/react-smartbanner/dist/main.css';

import { toggleAppSidebar } from '../../../modules/app';
import {
  saveReadingData,
  updateSpineIndex,
  fetchReadingData,
  updateReadingData,
  resetReader,
} from '../../../modules/readerApp';
import { fetchCurrentBookAsReader } from '../../../modules/book';
import ChapterCommentsList from '../../comment/commentsList';
import InlineCommentsList from '../../comment/inlineCommentsList';
import ChapterMenu from './footer/chapterMenu';
import { PaddedWrapper } from '../../../style';

// start monitoring and tracking
import {
  addTimeSpent,
  trackTimeSpent,
  updateFocus,
} from '../../../modules/monitor';

import {
  CLOSED_BOOK,
  REACHED_PERCENTILE,
  TIME_SPENT,
  track as doTrack,
} from '../../../utils/tracker';
import { authUtil } from '../../../utils';

// end monitoring and tracking

const ReaderWrapper = styled.div`
  height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr;

  margin-top: -15px;
  width: 100%;
  font-size: ${props => (props.fontSize ? props.fontSize : 1)}em;
`;

const MaxWidthWrapper = styled.div`
  ${({ maxWidth = `${MaxTextWidth}px` }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth}px;
    `}
`;

const ChapterWrapper = styled.div`
  display: grid;
  padding-left: 1em;
  padding-right: 1em;
  ${({ sidebarVisible }) =>
    !sidebarVisible
      ? css`
          grid-template-columns: 1fr minmax(0, ${MaxTextWidth}px) 1fr;
        `
      : css`
          grid-template-columns: minmax(0, ${MaxTextWidth}px) 1fr;
        `}
`;

const SidebarCommentsWrapper = styled.div`
  ${MediaQuery.phone} {
    display: none;
  }
  max-width: var(--sidebar-width);
  width: var(--sidebar-width);
`;

const trackingInterval = 10; // seconds
const monitoringInterval = 1; // seconds
const maxInActivityTime = 300; // seconds

const Reader = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const location = useLocation();

  const user = useSelector(state => state.user.user);
  const userProfile = useSelector(state => state.user.userProfile);
  const defaultColorMode = useSelector(state => state.app.defaultColorMode);
  const currentBook = useSelector(state => state.book.currentBook);
  const backendReadingData = useSelector(
    state => state.readerApp.backendReadingData
  );
  const readingData = useSelector(state => state.readerApp.readingData);
  const globalPercentagePassed = useSelector(
    state => state.readerApp.percentagePassed
  );
  const currentSpineIndex = useSelector(
    state => state.readerApp.currentSpineIndex
  );
  const fontSizes = useSelector(state => state.app.fontSizes);
  const readingTimeCounters = useSelector(
    state => state.monitor.readingTimeCounters
  );
  const isFocused = useSelector(state => state.monitor.isFocused);
  const isOutsideReader = useSelector(state => state.monitor.isOutsideReader);
  const defaultFontSize = useSelector(state => state.app.defaultFontSize);
  const sidebarVisible = useSelector(state => state.app.sidebarVisible);
  const [currentItemTopPercentagePassed, setCurrentItemTopPercentagePassed] =
    useState();
  const [trackedPercentiles, setTrackedPercentiles] = useState({});

  const shouldNotTrack =
    !currentBook ||
    !userProfile ||
    currentBook.author?._id === userProfile?._id;

  // track calls track if the user is logged in and not the author / query param viewAsReader
  const track = useCallback(
    args => {
      if (!shouldNotTrack) {
        doTrack(args);
      }
    },
    [shouldNotTrack]
  );

  // Use references to allow setTimeout and setInterval functions to access the most recent values:
  const currentSpineIndexRef = useRef(currentSpineIndex);
  currentSpineIndexRef.current = currentSpineIndex;
  const currentBookRef = useRef(currentBook);
  currentBookRef.current = currentBook;
  const readingTimeCountersRef = useRef(readingTimeCounters);
  readingTimeCountersRef.current = readingTimeCounters;
  const sessionTimeout = useRef(null);
  const readingTimeMonitor = useRef(null);
  const timeSpentTracker = useRef(null);

  useEffect(() => {
    resetState();
    fetchCurrentBook();
    dispatch(toggleAppSidebar(false));

    const initiateActivityMonitoring = () => {
      // intervals and event listeners
      resumeReadingTimeMonitor();
      // start or resume collecting reading time data when the browser window gets focus
      window.addEventListener('focus', onFocus);
      // stop collecting reading time data when the browser window loses focus
      window.addEventListener('blur', onBlur);
      // track collected data on predefined tracking interval
      timeSpentTracker.current = setInterval(
        doTrackTimeSpent,
        trackingInterval * 1000
      );
      window.addEventListener('scroll', startSessionTimer);
      window.addEventListener('mousemove', startSessionTimer);
    };

    const removeActivityMonitorying = () => {
      pauseReadingTimeMonitor();
      clearTimeout(sessionTimeout.current);
      sessionTimeout.current = null;
      clearInterval(timeSpentTracker.current);
      timeSpentTracker.current = null;
      window.removeEventListener('scroll', startSessionTimer);
      window.removeEventListener('mousemove', startSessionTimer);
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };

    initiateActivityMonitoring();

    return () => {
      removeActivityMonitorying();
      trackClosedBook(
        user,
        currentBookRef.current,
        currentSpineIndexRef.current
      );
      dispatch(resetReader());
    };
  }, []);

  useEffect(() => {
    if (
      !!user &&
      !!currentBook &&
      !currentBook?.err &&
      currentSpineIndex !== undefined
    ) {
      trackOpenedBook(user, currentBook, currentSpineIndex);
    }
  }, [currentBook, user, currentBook]);

  useEffect(() => {
    if (
      !currentBook?.err &&
      Number.isInteger(currentSpineIndex) &&
      currentBook?.parts &&
      currentBook?.parts[currentSpineIndex]
    ) {
      const currentItem = currentBook.parts[currentSpineIndex];
      if (
        currentItem.kind.includes('Survey', 'Cover') &&
        currentItemTopPercentagePassed !== 0
      ) {
        setCurrentItemTopPercentagePassed(0);
      }
    }
  }, [currentSpineIndex, currentBook]);

  // if focus has changed
  useEffect(() => {
    handleFocusChange({
      isFocused: isFocused,
      isOutsideReader: isOutsideReader,
    });
  }, [isFocused, isOutsideReader]);

  useEffect(() => {
    if (currentBook) {
      const parsedQueryString = queryString.parse(location.search);
      const { part } = parsedQueryString;
      if (part) {
        // if we are asked to go to a specific part, do that
        const partIndex = currentBook.parts.findIndex(
          entry => entry._id === part
        );
        if (partIndex > -1) {
          dispatch(updateSpineIndex(partIndex));
        }
      } else {
        // if else, fetch backend reading data (if any) and use that
        doFetchReadingData();
      }
    }
  }, [currentBook]);

  const onFocus = () => {
    dispatch(updateFocus(true));
  };

  const onBlur = () => {
    dispatch(updateFocus(false));
  };

  useEffect(() => {
    if (
      currentBook?._id !== undefined &&
      readingData !== undefined &&
      backendReadingData !== undefined &&
      currentSpineIndex === undefined
    ) {
      handleReadingdata({
        localReadingData: readingData[user.uid] || {},
        backendReadingData,
      });
    }
  }, [currentBook?._id, backendReadingData, currentSpineIndex]);

  useEffect(() => {
    if (globalPercentagePassed !== undefined && currentBook !== undefined) {
      trackChangedPercentile(user, currentBook, globalPercentagePassed);
    }
  }, [globalPercentagePassed, currentBook]);

  const doFetchReadingData = async () => {
    const idToken = await authUtil.getFreshIdToken();
    dispatch(fetchReadingData({ user, idToken }, currentBook._id));
  };

  // update book id if url has changed
  const getCurrentBookId = useCallback(() => {
    const parsedQueryString = queryString.parse(location.search);
    const bookId = parsedQueryString.book;
    return bookId;
  }, [location.search]);

  const resetState = () => {
    setCurrentItemTopPercentagePassed(undefined);
    setTrackedPercentiles({});
  };

  const handleReadingdata = ({ localReadingData, backendReadingData }) => {
    // activate the most completed reached readingdata
    if (
      backendReadingData?.globalPercentagePassed >
      localReadingData[currentBook._id]?.globalPercentagePassed
    ) {
      activateReadingData(backendReadingData);
    } else {
      activateReadingData(localReadingData[currentBook._id]);
    }
  };

  const fetchCurrentBook = async () => {
    const idToken = await authUtil.getFreshIdToken();
    const bookId = getCurrentBookId();
    const parsedQueryString = queryString.parse(location.search);
    const { currentContentVersionId } = parsedQueryString;
    dispatch(
      fetchCurrentBookAsReader(idToken, bookId, currentContentVersionId)
    );
  };

  const percentagePassedUpdated = percentageData => {
    const percentagePassed = percentageData.bottom; // use this for global progress bar etc
    const currentItem = currentBook.parts[currentSpineIndex];
    let updatedPercentagePassedGlobally = undefined;
    setCurrentItemTopPercentagePassed(percentageData.top);
    if (['Survey', 'Cover'].includes(currentItem.kind)) {
      // updateGlobalPercentagePassed(percentagePassed);
    } else {
      const wordsPassedLocally =
        currentBook.parts[currentSpineIndex].wordCount * percentagePassed;
      const wordsPassedGlobally =
        currentBook.parts[currentSpineIndex].aggregatedWordCount -
        currentBook.parts[currentSpineIndex].wordCount +
        wordsPassedLocally;
      updatedPercentagePassedGlobally =
        wordsPassedGlobally / currentBook.wordCount;
      // updateGlobalPercentagePassed(percentagePassedGlobally);
    }
    doSaveReadingData(false, updatedPercentagePassedGlobally);
  };

  const doSaveReadingData = async (
    forceSaveToBackend,
    updatedPercentagePassedGlobally
  ) => {
    if (shouldNotTrack) {
      return;
    }
    const idToken = await authUtil.getFreshIdToken();
    if (
      user !== undefined &&
      idToken !== undefined &&
      currentBook !== undefined
    ) {
      const newReadingData = {
        chapter: currentBook.parts[currentSpineIndex]._id,
        percentagePassed: currentItemTopPercentagePassed,
        part: {
          id: currentBook.parts[currentSpineIndex]._id,
          percentagePassed: currentItemTopPercentagePassed,
        },
        globalPercentagePassed:
          updatedPercentagePassedGlobally !== undefined
            ? updatedPercentagePassedGlobally
            : 0,
        timestamp: new Date().toJSON(),
      };
      dispatch(
        saveReadingData(
          user,
          idToken,
          currentBook._id,
          newReadingData,
          forceSaveToBackend
        )
      );
    }
  };

  const activateReadingData = readingData => {
    dispatch(
      updateReadingData(user, currentBook._id, readingData, () => {
        const newSpineIndex =
          readingData !== undefined
            ? currentBook.parts.findIndex(part => {
                return part._id === readingData.chapter;
              })
            : 0;
        const newPercentagePassed =
          readingData !== undefined ? readingData.percentagePassed : 0;
        dispatch(updateSpineIndex(newSpineIndex));
        setCurrentItemTopPercentagePassed(newPercentagePassed);
      })
    );
  };

  const doAddTimeSpent = seconds => {
    try {
      const book = currentBookRef?.current;
      const spineIndex = currentSpineIndexRef.current;
      if (!book || book?.err) return;
      const visibleParagraphs = getVisibleParagraphs();
      const currentChapter = book.parts[spineIndex];
      if (!currentChapter) return;
      visibleParagraphs.forEach(paragraph => {
        const data = {
          event: { id: TIME_SPENT, count: seconds / visibleParagraphs.length },
          user: { id: user.uid },
          book: { id: book._id },
          chapter: { id: currentChapter._id },
          paragraph: { number: paragraph.number },
        };
        dispatch(addTimeSpent(data));
      });
    } catch (err) {
      console.error(err);
    }
  };

  const doTrackTimeSpent = () => {
    if (shouldNotTrack) {
      return;
    }
    dispatch(trackTimeSpent(readingTimeCountersRef.current));
  };

  const getVisibleParagraphs = () => {
    const chapterParagraphs = document.getElementsByClassName('br-cp');
    const visibleParagraphs = [];
    for (let index = 0; index < chapterParagraphs.length; index++) {
      const paragraph = chapterParagraphs[index];
      // const paragraphNumber = parseInt(paragraph.dataset.pn);
      const paragraphNumber = getParagraphNumber(paragraph);
      if (isElementInViewport(paragraph)) {
        visibleParagraphs.push({ number: paragraphNumber });
      }
    }
    return visibleParagraphs;
  };

  const getParagraphNumber = paragraphElement => {
    const paragraphRegex = /(br-pn)([0-9]*)/;
    const classes = paragraphElement.className;
    const match = classes.match(paragraphRegex);
    const paragraphNumber = parseInt(match[2]);
    return paragraphNumber;
  };

  const startSessionTimer = () => {
    clearTimeout(sessionTimeout.current);
    // if there is no reading time monitor, create it
    resumeReadingTimeMonitor();
    sessionTimeout.current = setTimeout(function () {
      sessionTimeout.current = null;
      pauseReadingTimeMonitor();
    }, maxInActivityTime * 1000);
  };

  /*
   * Returns true if at least half the element is within the viewport from a vertical perspective.
   */
  const isElementInViewport = el => {
    var rect = el.getBoundingClientRect();

    const fullHeight = rect.bottom - rect.top;
    const fivePercentOfHeight = fullHeight * 0.05;
    const footerHeight = 52;
    const headerHeight = 43;

    return (
      rect.top >= 0 - (fullHeight - fivePercentOfHeight - footerHeight) &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) +
          (fullHeight - fivePercentOfHeight - headerHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  const handleFocusChange = ({ isFocused, isOutsideReader }) => {
    if (isFocused && !isOutsideReader) {
      resumeReadingTimeMonitor();
    } else {
      pauseReadingTimeMonitor();
    }
  };

  const resumeReadingTimeMonitor = () => {
    if (readingTimeMonitor.current !== null) return;
    clearInterval(readingTimeMonitor.current);
    readingTimeMonitor.current = setInterval(function () {
      doAddTimeSpent(monitoringInterval);
    }, monitoringInterval * 1000);
  };

  const pauseReadingTimeMonitor = () => {
    clearInterval(readingTimeMonitor.current);
    readingTimeMonitor.current = null;
  };

  const trackOpenedBook = (user, book, spineIndex) => {
    /*
    * removed this because it messes up session tracking (if fired at the same time as another event and the session has expired, we will get one new session for this event and one for the other)
    const visibleParagraphs = getVisibleParagraphs();
   
    track({
      book: book._id,
      data: {
        event: {id: OPENED_BOOK},
        user: {id: user.uid},
        book: {id: book._id},
        chapter: {id: book.parts[spineIndex || 0]._id},
        paragraph: {number: visibleParagraphs.length > 0 ? visibleParagraphs[0].number : null}
      }
    })
    */
  };

  const trackClosedBook = (user, book, spineIndex) => {
    const visibleParagraphs = getVisibleParagraphs();
    const trackingData = {
      event: { id: CLOSED_BOOK },
      user: { id: user.uid },
      book: { id: book._id },
      paragraph: {
        number:
          visibleParagraphs.length > 0 ? visibleParagraphs[0].number : null,
      },
    };
    const part = book.parts[spineIndex || 0];
    if (part && part.type === 'Chapter') {
      trackingData.chapter = { id: part._id };
    }
    track({
      book: book._id,
      data: [trackingData],
    });
  };

  const getPercentile = percentage => Math.floor(percentage * 10) * 10;

  const trackChangedPercentile = (user, book, percentage) => {
    const percentile = getPercentile(percentage);
    if (trackedPercentiles[percentile.toString()]) {
      return;
    } else {
      let updatedTrackedPercentiles = { ...trackedPercentiles };
      updatedTrackedPercentiles[percentile.toString()] = true;
      setTrackedPercentiles({ ...updatedTrackedPercentiles });
      track({
        book: book._id,
        data: [
          {
            event: { id: REACHED_PERCENTILE, count: percentile },
            user: { id: user.uid },
            book: { id: book._id },
          },
        ],
      });
    }
  };

  const getCurrentColorMode = useCallback(() => {
    return userProfile?.readerSettings?.colorMode ?? defaultColorMode;
  }, [userProfile?.readerSettings?.colorMode]);

  const getFontSizeEm = useCallback(() => {
    const currentFontSize =
      userProfile?.readerSettings?.fontSize ?? defaultFontSize;
    return fontSizes.find(size => size.name === currentFontSize)?.size ?? 1;
  }, [fontSizes, userProfile?.readerSettings?.fontSize]);
  if (!currentBook) {
    return (
      <Container
        className="container"
        text
        style={{ position: 'absolute', top: 200 }}
      >
        <Loader size="massive">Loading...</Loader>
      </Container>
    );
  }
  if (currentBook?.err) {
    return (
      <Container text>
        <Message error>{currentBook.err}</Message>
      </Container>
    );
  }
  if (currentSpineIndex !== undefined) {
    const currentPart = currentBook.parts[currentSpineIndex];
    return (
      <ReaderWrapper fontSize={getFontSizeEm()}>
        <div style={{ marginBottom: '1em' }}>
          <Progress
            key="ReadingProgressBar"
            percent={Number(globalPercentagePassed * 100).toFixed()}
            size="tiny"
            color="orange"
            inverted
            className="reader-progress-bar"
            style={{ position: 'fixed', top: 40, width: '100%' }}
          />
          <SmartBanner title="BetaReader" position="top" />
        </div>
        <PaddedWrapper>
          {currentPart.kind === 'Cover' && (
            <MaxWidthWrapper>
              <Cover
                cover={currentPart}
                percentagePassedUpdated={percentagePassedUpdated}
              />
            </MaxWidthWrapper>
          )}
          {currentPart.kind === 'Chapter' && (
            <ChapterWrapper sidebarVisible={sidebarVisible}>
              {
                // add padding div when sidebar is not visible in order to center text content in grid
                // (this div takes the left-most column)
                !sidebarVisible && <div />
              }
              <div>
                <div>
                  <Header
                    inverted={getCurrentColorMode() === 'dark'}
                    textAlign="center"
                    size="huge"
                    content={currentBook.parts[currentSpineIndex].title}
                    style={{ marginBottom: '2em' }}
                  />
                  <TextSegment
                    percentagePassedUpdated={percentagePassedUpdated}
                    text={currentBook.parts[currentSpineIndex].html}
                    chapter={currentBook.parts[currentSpineIndex]} // todo: change this to '.text' or '.content'
                    initialPercentagePassed={currentItemTopPercentagePassed}
                    dir={language.getTextDirection(currentBook.language)}
                  />
                </div>
                <Divider hidden />
                <ChapterMenu standAlone />
                {currentBook.enableChapterComments &&
                  currentPart.kind === 'Chapter' && [
                    <Divider key="divider" hidden clearing />,
                    <Header key="header" as="h3" dividing>
                      {t('Comments')}
                    </Header>,
                    <ChapterCommentsList
                      key="chaptercomments"
                      filter="ChapterComment"
                    />,
                  ]}
              </div>
              <Media greaterThanOrEqual="tablet">
                {(className, renderChildren) =>
                  renderChildren && (
                    <SidebarCommentsWrapper>
                      {currentPart.kind === 'Chapter' && [
                        <Header
                          className="br-text"
                          key="comments-header"
                          as="h4"
                          content={t('Comments')}
                        />,
                        <InlineCommentsList
                          positioning="sidebar"
                          key="comments"
                          disableLeavingComments
                          showOnly={{ type: 'InlineComment' }}
                        />,
                      ]}
                    </SidebarCommentsWrapper>
                  )
                }
              </Media>
              <Media lessThan="tablet">
                {(className, renderChildren) =>
                  renderChildren && (
                    <InlineCommentsList
                      positioning="bottom"
                      key="comments"
                      disableLeavingComments
                      enableFilters={false}
                      showOnly={{ type: 'InlineComment' }}
                    />
                  )
                }
              </Media>
            </ChapterWrapper>
          )}
          {currentPart.kind === 'Survey' && (
            <ChapterWrapper sidebarVisible={sidebarVisible}>
              {
                // add padding div when sidebar is not visible in order to center text content in grid
                // (this div takes the left-most column)
                !sidebarVisible && <div />
              }
              <div>
                <Header
                  inverted={getCurrentColorMode() === 'dark'}
                  textAlign="center"
                  size="huge"
                  content={currentBook.parts[currentSpineIndex].title}
                  style={{ marginBottom: '2em' }}
                />
                <Survey percentagePassedUpdated={percentagePassedUpdated} />
                <Divider hidden />
                <ChapterMenu standAlone />
              </div>
            </ChapterWrapper>
          )}
        </PaddedWrapper>
      </ReaderWrapper>
    );
  }
  return null;
};

export default Reader;
