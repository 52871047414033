import React, { useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import { useRouteMatch, withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Menu, Icon, Button, Dropdown } from 'semantic-ui-react';
import queryString from 'query-string';

import { saveBook } from '../../modules/book';

import { TitleMenuItemWrapper, TitleWrapper } from './contextMenu';
import ContentAutoSaveButton from './contentAutoSaveButton';
import SidebarToggler from './sidebarToggler';
import styled from 'styled-components';

const InfoDropdown = styled(Dropdown)`
  margin-right: 20px;
`;

const EditBookContextMenu = ({
  currentBook,
  currentContentVersion,
  currentPartTitle,

  t,
}) => {
  const match = useRouteMatch('/books/:bookId/content/v:versionNumber/:partId');
  const getReaderLink = useCallback(() => {
    if (match && currentBook?._id) {
      const {
        params: { partId },
      } = match;
      return `/read?${queryString.stringify({
        book: currentBook._id,
        currentContentVersionId: currentContentVersion?._id,
        currentPartId: partId,
        viewAsReader: true,
      })}`;
    }
    return `/read?${queryString.stringify({
      book: currentBook._id,
      viewAsReader: true,
    })}`;
  }, [currentBook?._id, currentContentVersion?._id, match]);
  if (!currentBook) {
    return null;
  }
  return (
    <>
      <SidebarToggler />
      <TitleMenuItemWrapper
        fitted
        key="title"
        header
        content={
          <TitleWrapper>
            <Icon name="book" />
            <span>{currentPartTitle || currentBook?.title || ''}</span>
          </TitleWrapper>
        }
      />
      <Menu.Item fitted position="right">
        <InfoDropdown
          fluid
          icon={null}
          floating
          direction="left"
          trigger={<Icon name="info" />}
        >
          <Dropdown.Menu>
            <Dropdown.Item>
              <a href={getReaderLink()} target="_blank" rel="noreferrer">
                <Button
                  size="tiny"
                  content={t('ViewAsReader')}
                  icon="eye"
                  compact
                  color="orange"
                />
              </a>
            </Dropdown.Item>
          </Dropdown.Menu>
        </InfoDropdown>
        <ContentAutoSaveButton position="right" />
      </Menu.Item>
    </>
  );
};

// redux stuff
const mapStateToProps = state => ({
  user: state.user.user,
  idToken: state.user.idToken,
  userProfile: state.user.userProfile,
  currentBook: state.book.currentBook,
  currentContentVersion: state.book.currentContentVersion,
  currentPartTitle: state.book.currentPartTitle,
  currentSpineIndex: state.readerApp.currentSpineIndex,
  pendingBookChanges: state.book.pendingBookChanges,
  pendingPartChanges: state.book.pendingPartChanges,
  saveButton: state.book.saveButton,
  percentagePassed: state.readerApp.percentagePassed,
  sidebarVisible: state.app.sidebarVisible,
  fontSizes: state.app.fontSizes,
  searchTerm: state.betaOpportunities.searchTerm,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      saveBook,
      changePage: newPage => push(newPage),
    },
    dispatch
  );

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(EditBookContextMenu))
);
