import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Menu, Icon, Header, Button, Dropdown } from 'semantic-ui-react';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import BookContentMenu from './bookContentMenu';
import VersionSelector from './versionSelector';
import SubMenu from './subMenu';
import { featureToggleUtil } from '../../utils';
import { toggleAppSidebar } from '../../modules/app';
import SubmitManuscriptButton from './submitManuscriptButton';
import { useDispatch, useSelector } from 'react-redux';

const Vertical = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
`;

const TitleSpan = styled(Header)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
`;

const VersionSpan = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  font-size: 0.9em;
`;

const ActionButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  > * {
    padding-top: 5px;
    padding-bottom: 5px;
  }
`;

const ActionMenu = styled(Dropdown.Menu)`
  width: 100%;
  background: rgb(255, 255, 255, 0.2) !important;
`;

const ActionItem = styled.div`
  padding: 3px !important;
`;

const BookMenu = () => {
  const { t } = useTranslation();
  const currentBook = useSelector(state => state.book.currentBook);
  const userProfile = useSelector(state => state.user.userProfile);
  const dispatch = useDispatch();

  const [showSubMenu, setShowSubMenu] = useState(false);
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    const unsubscribeHistoryListener = history.listen(() => {
      // close submenu if changing location in small screen mode
      setShowSubMenu(false);
    });
    return () => {
      unsubscribeHistoryListener();
    };
  }, [history]);

  const canEdit = useCallback(() => {
    return ['author', 'collaborator'].includes(currentBook?.role);
  }, [currentBook?.role]);

  const isOnPath = useCallback(
    path => {
      const pathPart = location.pathname.replace(
        `/books/${currentBook?._id}`,
        ''
      );
      if (path === 'audience') {
        return (
          pathPart === '/readers' ||
          pathPart === '/followers' ||
          pathPart === '/feed' ||
          pathPart === '/messages' ||
          false
        );
      }
      if (path === 'feedback') {
        return pathPart.includes('feedback');
      }
      return false;
    },
    [location?.pathname, currentBook?._id]
  );

  const shouldShowSubMenu = path => {
    const isActive = showSubMenu === path;
    if (!isActive) {
      return isOnPath(path);
    }
    return isActive;
  };

  const getAudienceSubMenu = useCallback(
    currentBook => {
      const menuList = [
        {
          key: 'readers',
          name: t('Readers'),
          icon: 'user outline',
          path: `/books/${currentBook._id}/readers`,
        },
        {
          key: 'followers',
          name: t('Followers'),
          icon: 'address book',
          path: `/books/${currentBook._id}/followers`,
        },
        {
          key: 'feed',
          name: t('Feed'),
          icon: 'feed',
          path: `/books/${currentBook._id}/feed`,
        },
      ];

      if (featureToggleUtil.hasFeatureAccess(userProfile, 'broadcast')) {
        menuList.push({
          key: 'messages',
          name: t('Messages'),
          icon: 'mail outline',
          path: `/books/${currentBook._id}/messages`,
        });
      }
      return menuList;
    },
    [userProfile, currentBook?._id]
  );

  const getFeedbackSubMenu = useCallback(
    currentBook => {
      const menuList = [
        {
          key: 'comments',
          name: t('Comments'),
          icon: 'comments outline',
          path: `/books/${currentBook._id}/feedback/comments`,
        },
        {
          key: 'reviews',
          name: t('Reviews'),
          icon: 'star outline',
          path: `/books/${currentBook._id}/feedback/reviews`,
        },
        {
          key: 'reports',
          name: t('Reports'),
          icon: 'chart area',
          path: `/books/${currentBook._id}/feedback/reports`,
        },
      ];
      return menuList;
    },
    [currentBook?._id]
  );

  if (!currentBook) return null;

  const OverviewItem = (
    <>
      <Menu.Item
        key="goback"
        content={
          <span>
            <Icon name="chevron left" />
            {t('Home')}
          </span>
        }
        onClick={() =>
          history.push(
            ['author', 'collaborator'].includes(currentBook?.role)
              ? '/mymanuscripts'
              : '/discover'
          )
        }
      />
      <Menu.Item
        key="title"
        content={
          <div>
            <span>{currentBook?.title}</span>
            <br />
            <span style={{ fontSize: '0.8em' }}>
              {currentBook?.author?.displayName}
            </span>
          </div>
        }
      />
      <Menu.Item
        key="overview"
        name={t('Overview')}
        content={t('Overview')}
        active={location.pathname.includes('/overview')}
        onClick={() => history.push(`/books/${currentBook._id}/overview`)}
        icon="settings"
      />
      {canEdit && (
        <Menu.Item
          key="summary"
          name={t('Synopsis')}
          content={t('Synopsis')}
          active={location.pathname.includes('/summary')}
          onClick={() => history.push(`/books/${currentBook._id}/summary`)}
          icon="clipboard list"
        />
      )}
    </>
  );

  if (!canEdit()) {
    return OverviewItem;
  }
  if (location.pathname.includes('/content')) {
    return (
      <>
        <Menu.Item
          content={
            <Vertical>
              <Horizontal>
                <TitleSpan sub size="small" inverted>
                  {t('EditContent')}
                  <div>
                    <Icon name="chevron left" />
                    <Icon
                      name="list alternate outline"
                      link
                      onClick={() => dispatch(toggleAppSidebar(false))}
                    />
                  </div>
                </TitleSpan>
              </Horizontal>
              <Horizontal>
                <VersionSpan>
                  {t('Version')}
                  <VersionSelector
                    key="version-selector"
                    inverted
                    size="tiny"
                  />
                </VersionSpan>
              </Horizontal>
            </Vertical>
          }
        />
        <BookContentMenu key="content-menu" />
      </>
    );
  } else {
    return (
      <>
        {OverviewItem}
        <Menu.Item
          key="audience"
          name={t('Audience')}
          content={t('Audience')}
          onClick={() => setShowSubMenu('audience')}
          icon="users"
        />
        {shouldShowSubMenu('audience') && (
          <SubMenu
            key="audience-submenu"
            items={getAudienceSubMenu(currentBook)}
          />
        )}
        {userProfile?.account?.product?.internalId !== 'free' && (
          <Menu.Item
            key="collaborators"
            name={t('Collaborators')}
            content={t('Collaborators')}
            active={location.pathname.includes('/collaborators')}
            onClick={() =>
              history.push(`/books/${currentBook._id}/collaborators`)
            }
            icon="user doctor"
          />
        )}
        <Menu.Item
          key="feedback"
          name={t('Feedback')}
          content={t('Feedback')}
          onClick={() => setShowSubMenu('feedback')}
          icon="pie chart"
        />
        {(showSubMenu === 'feedback' ||
          location.pathname.includes('/feedback')) && (
          <SubMenu
            key="feedback-submenu"
            items={getFeedbackSubMenu(currentBook)}
          />
        )}
        {canEdit() && (
          <>
            <Menu.Item
              key="Editor"
              content={
                <ActionButtonWrapper>
                  <Button
                    fluid
                    color="orange"
                    compact
                    size="tiny"
                    onClick={() =>
                      history.push(`/books/${currentBook._id}/content`)
                    }
                    content={t('EditContent')}
                  />
                  <Dropdown
                    fluid
                    icon={null}
                    floating
                    trigger={
                      <Button
                        compact
                        size="tiny"
                        fluid
                        // icon='dropdown'
                      >
                        <span>{t('Actions')}</span> <Icon name="dropdown" />
                      </Button>
                    }
                  >
                    <ActionMenu>
                      <ActionItem>
                        <a
                          href={`/read?${queryString.stringify({
                            book: currentBook._id,
                            viewAsReader: true,
                          })}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Button
                            fluid
                            compact
                            size="tiny"
                            content={t('ViewAsReader')}
                          />
                        </a>
                      </ActionItem>
                      <ActionItem>
                        <SubmitManuscriptButton size="tiny" />
                      </ActionItem>
                    </ActionMenu>
                  </Dropdown>
                </ActionButtonWrapper>
              }
            />
          </>
        )}
      </>
    );
  }
};

export default BookMenu;
