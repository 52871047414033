import React, { useEffect, useRef, useState } from 'react';
import { Redirect, Link, useLocation, useHistory } from 'react-router-dom';
import {
  Button,
  Image,
  Ref,
  Header,
  Icon,
  Grid,
  Menu,
  Form,
  Message,
  Divider,
} from 'semantic-ui-react';
import queryString from 'query-string';
// i18n
import { Trans, useTranslation } from 'react-i18next';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { Media } from 'src/utils/Media';

import ReactGA from 'react-ga';

import SalesPitch from '../../components/standard-messages/salesPitch';
import { authUtil, userProfileUtil } from '../../utils';
import ForgotPasswordWidget from 'src/components/ForgotPasswordWidget';
import fire from 'src/config/fire';
import { getUserProfile } from 'src/modules/user';

const SignIn = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userProfile = useSelector(state => state.user.userProfile);
  const user = useSelector(state => state.user.user);

  const history = useHistory();
  const location = useLocation();

  const emailInput = useRef();
  const passwordInput = useRef();
  const [redirect, setRedirect] = useState(null);
  const [show, setShow] = useState('stage1');
  const [stateUser, setStateUser] = useState(null);
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState<null | string | undefined>(
    null
  );
  const [message, setMessage] = useState(null);
  const [signingIn, setSigningIn] = useState(false);
  const [verifyingMagicLink, setVerifyingMagicLink] = useState(false);
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const [token, setToken] = useState(null);

  const getToken = async () => {
    const _token = await authUtil.getFreshIdToken();
    console.log('got token', _token);

    setToken(_token);
  };

  useEffect(() => {
    console.log('getting token');

    getToken();
  }, []);

  // handle first mount
  useEffect(() => {
    const handleFirstLoad = async () => {
      ReactGA.pageview(location.pathname + location.hash + location.search);

      const {
        redirect,
        invitationToken: refererToken,
        ur: userRole,
      } = queryString.parse(location.search.replace('?', ''));
      if (refererToken) {
        localStorage.setItem('br-signup-referer-token', refererToken);
      }
      if (userRole) {
        localStorage.setItem('br-signup-user-role', userRole);
      }

      setRedirect(redirect);
      setShow('stage1');
      const signInMode = localStorage.getItem('sign-in-mode');
      if (signInMode !== null && ['redirect', 'popup'].includes(signInMode)) {
        localStorage.removeItem('sign-in-mode');
        setSigningIn(true);
      }

      const isSignInWithEmailLink = fire
        .auth()
        .isSignInWithEmailLink(window.location.href);
      if (isSignInWithEmailLink) {
        const _email = window.localStorage.getItem('emailForSignIn');
        if (!_email) {
          // User opened the link on a different device. To prevent session fixation
          // attacks, ask the user to provide the associated email again.
          setShow('stage3');
        } else {
          setEmail(_email);
          setVerifyingMagicLink(true);
        }
      }

      try {
        if (emailInput.current) {
          emailInput.current.querySelector('input').focus();
        }

        const redirectResult = await fire.auth().getRedirectResult();

        // The signed-in user info.
        localStorage.removeItem('sign-in-mode');
        const { user: _user } = redirectResult;
        setStateUser(_user);

        if (redirectResult.credential) {
          // This gives you a Google Access Token.
          const credential = redirectResult.credential;
        }

        if (!redirectResult.user) {
          // show sign in
          setShow('stage1');
        }
      } catch (error) {
        setErrorMessage(error.message);
      }
    };

    handleFirstLoad();
  }, []);

  useEffect(() => {
    if (verifyingMagicLink) {
      signInWithMagicLink();
    }
  }, [verifyingMagicLink]);

  useEffect(() => {
    if (passwordInput.current && show === 'stage2') {
      // if the password input box just appeared
      try {
        passwordInput.current.querySelector('input').focus();
      } catch (e) {
        // do nothing
      }
    }
  }, [show]);

  // hack to handle if user is already signed in, but userProfile is not yet loaded
  useEffect(() => {
    if (!userProfile) {
      const intervalId = setInterval(async () => {
        const idToken = await authUtil.getFreshIdToken();

        if (idToken) {
          dispatch(getUserProfile(idToken));
        }
      }, 5000);
      return () => clearInterval(intervalId);
    }
  }, []);

  const passwordChanged = (event, data) => {
    setPassword(data.value);
    setErrorMessage(undefined);
  };

  const emailChanged = event => {
    const newEmail = event.currentTarget.value;
    setEmail(newEmail);
    setErrorMessage(undefined);
  };

  const signIn = async (type: 'email' | 'apple' | 'google' | 'facebook') => {
    const appleAuthProvider = new fire.auth.OAuthProvider('apple.com');
    const googleAuthProvider = new fire.auth.GoogleAuthProvider();
    const facebookAuthProvider = new fire.auth.FacebookAuthProvider();

    try {
      switch (type) {
        case 'email':
          await fire.auth().signInWithEmailAndPassword(email, password);
          break;
        case 'apple':
          if (process.env.NODE_ENV === 'development') {
            await fire.auth().signInWithPopup(appleAuthProvider);
          } else {
            localStorage.setItem('sign-in-mode', 'redirect');
            await fire.auth().signInWithRedirect(appleAuthProvider);
          }
          break;
        case 'google':
          if (process.env.NODE_ENV === 'development') {
            await fire.auth().signInWithPopup(googleAuthProvider);
          } else {
            localStorage.setItem('sign-in-mode', 'redirect');
            await fire.auth().signInWithRedirect(googleAuthProvider);
          }
          break;
        case 'facebook':
          if (process.env.NODE_ENV === 'development') {
            await fire.auth().signInWithPopup(facebookAuthProvider);
          } else {
            localStorage.setItem('sign-in-mode', 'redirect');
            await fire.auth().signInWithRedirect(facebookAuthProvider);
          }
          break;
        default:
      }
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const handleEnterEmail = () => {
    if (userProfileUtil.validateEmail(email)) {
      setShow('stage2');
    }
  };

  const handleEnterPassword = () => {
    signIn('email');
  };

  const handleUseMagicLink = () => {
    setLoading('magic-link');
  };

  useEffect(() => {
    const handleLoadingChange = async () => {
      try {
        const actionCodeSettings = {
          // URL you want to redirect back to. The domain (www.example.com) for this
          // URL must be whitelisted in the fire Console.
          url: window.location.href,
          // This must be true.
          handleCodeInApp: true,
        };

        await fire.auth().sendSignInLinkToEmail(email, actionCodeSettings);
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem('emailForSignIn', email);
        setMessage(t('LinkWasSentToX', { x: email }));
      } catch (error) {
        // Some error occurred, you can inspect the code: error.code
        // const errorCode = error.code;
        setErrorMessage(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (loading === 'magic-link') {
      handleLoadingChange();
    }
  }, [loading]);

  const signInWithMagicLink = async () => {
    try {
      await fire.auth().signInWithEmailLink(email, window.location.href);
      // Clear email from storage.
      window.localStorage.removeItem('emailForSignIn');
      // You can access the new user via result.user
      // Additional user info profile not available via:
      // result.additionalUserInfo.profile == null
      // You can check if the user is new or existing:
      // result.additionalUserInfo.isNewUser
    } catch (error) {
      // ignore
    }
  };

  if (user !== undefined && userProfile !== undefined) {
    return (
      <Redirect
        to={
          redirect ||
          (['WRITER', 'BOTH'].includes(userProfile.role)
            ? '/mymanuscripts'
            : '/discover')
        }
      />
    );
  }

  const resetEverything = async () => {
    // reset everything locally
    setErrorMessage(undefined);
    setMessage(null);
    setSigningIn(false);
    setVerifyingMagicLink(false);
    setEmail('');
    setPassword('');
    setLoading(false);
    setShow('stage1');

    // reset firebase
    try {
      await fire.auth().signOut();
    } catch (error) {
      // ignore
    }
  };

  return (
    <Grid
      textAlign="center"
      style={{ height: '100%', width: '100%' }}
      stackable
      columns="equal"
      verticalAlign="top"
    >
      <Grid.Row>
        <Grid.Column style={{ maxWidth: 450 }}>
          <Image src="/assets/images/BR-orange-horizontal.png" />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Media greaterThan="tablet">
          {(className, renderChildren) =>
            renderChildren && (
              <Grid.Column style={{ maxWidth: 450 }} textAlign="left">
                <SalesPitch />
              </Grid.Column>
            )
          }
        </Media>
        <Grid.Column style={{ maxWidth: 450 }}>
          <Form error={errorMessage !== undefined} loading={signingIn}>
            <Menu text>
              <Menu.Menu position="right">
                <Menu.Item
                  active
                  color="orange"
                  as={Link}
                  to={`/signin${location.search}`}
                >
                  {t('SignIn')}
                </Menu.Item>
                <Menu.Item as={Link} to={`/signup${location.search}`}>
                  {t('CreateAccount')}
                </Menu.Item>
              </Menu.Menu>
            </Menu>
            <Message error attached="bottom" content={errorMessage} />
            {message && <Message info attached="bottom" content={message} />}
            {user === undefined && (
              <React.Fragment>
                {show === 'stage1' && (
                  <React.Fragment>
                    <Form.Input
                      ref={emailInput}
                      key="input"
                      size="large"
                      fluid
                      icon="user"
                      iconPosition="left"
                      onChange={emailChanged}
                      placeholder={t('Email')}
                      action={
                        <Button
                          type="submit"
                          onClick={handleEnterEmail}
                          icon="chevron right"
                        />
                      }
                    />
                    <Divider key="divider" horizontal>
                      {t('OrUse')}
                    </Divider>
                    <Form.Field key="social-signin">
                      <Grid>
                        <Grid.Column>
                          <Grid.Row style={{ paddingBottom: '0.9em' }}>
                            <Button
                              basic
                              circular
                              fluid
                              size="large"
                              onClick={() => {
                                signIn('facebook');
                              }}
                            >
                              <Button.Content>
                                <Grid>
                                  <Grid.Row stretched columns={3}>
                                    <Grid.Column floated="left" width={2}>
                                      <Icon
                                        style={{ color: '#3B5998' }}
                                        name="facebook"
                                      />
                                    </Grid.Column>
                                    <Grid.Column
                                      width={12}
                                      style={{ textAlign: 'center' }}
                                    >
                                      {t('ContinueWithFacebook')}
                                    </Grid.Column>
                                    <Grid.Column width={2} />
                                  </Grid.Row>
                                </Grid>
                              </Button.Content>
                            </Button>
                          </Grid.Row>
                          <Grid.Row style={{ paddingBottom: '0.9em' }}>
                            <Button
                              basic
                              circular
                              fluid
                              size="large"
                              onClick={() => {
                                signIn('google');
                              }}
                            >
                              <Button.Content>
                                <Grid>
                                  <Grid.Row stretched columns={3}>
                                    <Grid.Column floated="left" width={2}>
                                      <Icon
                                        style={{ color: '#db3236' }}
                                        name="google"
                                      />
                                    </Grid.Column>
                                    <Grid.Column
                                      width={12}
                                      style={{ textAlign: 'center' }}
                                    >
                                      {t('ContinueWithGoogle')}
                                    </Grid.Column>
                                    <Grid.Column width={2} />
                                  </Grid.Row>
                                </Grid>
                              </Button.Content>
                            </Button>
                          </Grid.Row>
                          <Grid.Row>
                            <Button
                              icon
                              labelPosition="right"
                              basic
                              circular
                              fluid
                              size="large"
                              onClick={() => {
                                signIn('apple');
                              }}
                            >
                              <Button.Content>
                                <Grid>
                                  <Grid.Row stretched columns={3}>
                                    <Grid.Column floated="left" width={2}>
                                      <Icon
                                        style={{ color: 'grey' }}
                                        name="apple"
                                      />
                                    </Grid.Column>
                                    <Grid.Column
                                      width={12}
                                      style={{ textAlign: 'center' }}
                                    >
                                      {t('ContinueWithApple')}
                                    </Grid.Column>
                                    <Grid.Column width={2} />
                                  </Grid.Row>
                                </Grid>
                              </Button.Content>
                            </Button>
                          </Grid.Row>
                        </Grid.Column>
                      </Grid>
                    </Form.Field>
                  </React.Fragment>
                )}
                {show === 'stage2' && (
                  <React.Fragment>
                    <Form.Input
                      key="email"
                      size="large"
                      fluid
                      icon="user"
                      iconPosition="left"
                      onChange={emailChanged}
                      value={email}
                    />
                    <Ref innerRef={passwordInput}>
                      <Form.Input
                        key="input"
                        size="large"
                        fluid
                        icon="lock"
                        iconPosition="left"
                        placeholder={t('Password')}
                        type="password"
                        onChange={passwordChanged}
                        action={
                          <Button
                            type="submit"
                            loading={loading === 'password'}
                            onClick={handleEnterPassword}
                            icon="chevron right"
                          />
                        }
                      />
                    </Ref>
                    <Divider key="divider" horizontal>
                      {t('Or').toUpperCase()}
                    </Divider>
                    <Button
                      key="use-magic-link"
                      color="black"
                      fluid
                      loading={loading === 'magic-link'}
                      onClick={handleUseMagicLink}
                      content={t('SendMagicLinkToX', { x: email })}
                    />
                  </React.Fragment>
                )}
                {show === 'stage3' && (
                  <React.Fragment>
                    <Header
                      textAlign="left"
                      key="header"
                      content="Re-enter your email"
                    />
                    <Form.Input
                      key="input"
                      size="large"
                      fluid
                      icon="user"
                      iconPosition="left"
                      input={
                        <Ref innerRef={emailInput}>
                          <input
                            onChange={emailChanged}
                            placeholder={t('Email')}
                          />
                        </Ref>
                      }
                    />
                    <Form.Button
                      key="enter"
                      primary
                      fluid
                      type="submit"
                      onClick={signInWithMagicLink}
                      content={t('Submit')}
                    />
                  </React.Fragment>
                )}
                <Divider hidden />
                <ForgotPasswordWidget />
              </React.Fragment>
            )}
          </Form>
          {(user !== undefined || verifyingMagicLink) && (
            <>
              <Message
                info
                header={
                  <span>
                    {user !== undefined ? t('Redirecting') : t('Verifying')}
                    <Icon name="spinner" loading style={{ marginLeft: 5 }} />
                  </span>
                }
              />
              <Message info>
                Is it taking too long?
                <br />
                <Button
                  onClick={() => {
                    resetEverything();
                  }}
                >
                  {t('Retry')}
                </Button>
              </Message>
            </>
          )}
        </Grid.Column>
        <Media
          as={Grid.Column}
          at="tablet"
          style={{ maxWidth: window.innerWidth }}
          textAlign="left"
        >
          <SalesPitch />
        </Media>
      </Grid.Row>
    </Grid>
  );
};

export default SignIn;
